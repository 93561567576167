/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 17:46:14
 * @Module: 退会管理列表
 */
 <template>
  <div class="reasonBox">
    <div class="header">
      <h6>退会管理</h6>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true" :size="'small'" :model="formInline" class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="快手号:">
              <el-input v-model="formInline.tick_account" placeholder="请输入要查询的快手号"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="req_list(true)">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <tablePage
        :tableData="tableData"
        :currentPage="formInline.page"
        :height="tableHeight"
        ref="tableList"
        @current-change="currentChange"
        v-loading="loading"
        :total="total"
        id="table"
      >
        <el-table-column label="主播信息" min-width="160">
          <template slot-scope="scope">
            <div class="base-wrap">
              <el-avatar :size="64" :src="scope.row.avatar"></el-avatar>
              <div style="display: flex;flex-direction: column;justify-content: space-around;">
                <p>{{ scope.row.tick_name }}</p>
                <p>快手号：{{ scope.row.tick_account }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="主播/经纪人（姓名）" min-width="180">
          <template slot-scope="scope">
            <p>主播：{{ scope.row.anchor_name }}</p>
            <p>经纪人：{{ scope.row.broker_name }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="申请时间">
          <template slot-scope="scope">
            <p style="width:80px;">{{ scope.row.apply_time }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="截止时间">
          <template slot-scope="scope">
            <p style="width:80px;">{{ scope.row.apply_end_time }}</p>
          </template>
        </el-table-column>
        <el-table-column label="退会理由" class-name="cus_padRight">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top-end">
              <div slot="content" style="max-width:200px;">{{scope.row.reason}}</div>
              <!-- <div style="cursor: pointer;">
                <i class="icon iconfont icon-wenhao" style="color:#509DED" v-if="scope.row.reason"></i>
              </div> -->
              <p class="reasonText">{{ scope.row.reason }}</p>
            </el-tooltip>
            
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>
 <script>
import tablePage from "components/tablePage";
import api from "@/api";
import tableHeight from 'mixins/tableHeight'
export default {
  data() {
    return {
      loading: true,
      formInline: {
        tick_account: null,
        page:1
      },
      tableData: [],
      total: 10,
      tableHeight: 1000,
    };
  },
  components: {
    tablePage,
  },
   mixins: [tableHeight],
  created() {
    this.req_list(true)
  },
  mounted() {
  },
  methods: {
    async _initState(params = {}) {
      this.loading = true;
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.anchorLeaveList(params);
      if (status_code === 200) {
        this.total = total;
        this.tableData = message;
        this.loading = false;
      }
    },
     // 请求列表
    req_list(refresh){
      if(refresh) this.formInline.page = 1
      this._initState(this.formInline)
    },
    currentChange(page) {
      this.formInline.page = page;
      this.req_list()
    }
  },
  computed: {},
};
</script>
<style lang="less" scoped>
@import "./style.less";
.reasonText{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.reasonBox /deep/ .el-table .cus_padRight .cell{
  padding-right: 38px;
}
</style>